import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'

import * as s from '../pages/news.module.scss'

const SignaxTechNextConference: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAX took part in TechNext conference 2023"
        description="TechNext is an annual forum for construction industry held by Al Suwaidi Computer company which is the official reseller of SIGNAX since now."
      />
      <NewsSectionBlock date="22.02.2023">
        <Typography variant="h1" color="blue">
          SIGNAX took part in TechNext conference 2023
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-02-22-signax-tech-next-conference//image-1.jpg"
            alt="SIGNAX took part in TechNext conference"
            title="SIGNAX took part in TechNext conference"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          TechNext is an annual forum for construction industry held by{' '}
          <Link
            href="https://www.suwaidillc.com"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Al Suwaidi Computer
          </Link>{' '}
          company. It took place on February, 2 in Swissotel Al Marooj Hotel
          Dubai this year and attracted around 100 delegates: developers,
          contractors, consultants in construction and design companies. Such
          famous companies as Autodesk, HP, Adobe were the partners of the
          event.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-02-22-signax-tech-next-conference//image-2.jpg"
            alt="SIGNAX took part in TechNext conference 2"
            title="SIGNAX took part in TechNext conference 2"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          SIGNAX is proud to be a valuable part of this event. We show the
          participants how they can increase their efficiency on construction
          site. It is especially important in terms of D33 Dubai Strategy.
          SIGNAX provided a stand where everyone could try how BIM technologies
          can help them be better and go digital on site.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex justify-center">
            <StaticImage
              width={440}
              src="../assets/images/news/2023-02-22-signax-tech-next-conference//image-3.jpg"
              alt="SIGNAX took part in TechNext conference 3"
              title="SIGNAX took part in TechNext conference 3"
              placeholder="blurred"
            />
            <StaticImage
              width={580}
              className="ml-4"
              src="../assets/images/news/2023-02-22-signax-tech-next-conference//image-4.jpg"
              alt="SIGNAX took part in TechNext conference 4"
              title="SIGNAX took part in TechNext conference 4"
              placeholder="blurred"
            />
          </div>
        </div>
        <Typography variant="body1">
          Al Suwaidi and SIGNAX signed the agreement which gives Al Suwaidi the
          status of certified reseller of SIGNAX services in UAE and MENA
          region. Both companies are planning active collaboration to make AEC
          industry better in 2023. Here is only a start of this interesting
          journey. SIGNAX is ready to partner with IT and technological
          companies to increase the business together!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SignaxTechNextConference
